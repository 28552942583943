.logo {
  height: 3em;
  padding-top: 1.5em;
  will-change: filter;
  transition: filter 300ms;
  filter: drop-shadow(0 0 2em #646cffaa);
}

@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  a:nth-of-type(2) .logo {
    animation: logo-spin infinite 20s linear;
  }
}

.css-1eed5fa-MuiInputBase-root-MuiInput-root::before {
  border-bottom: red;
}

.custom-form-control-label .MuiFormControlLabel-label {
  color: #c0c0c0;
  font-size: 11px;
}

.css-i4bv87-MuiSvgIcon-root {
  width: 20px;
  height: 20px;
}

.ant-select-selection-placeholder {
  color: #c0c0c0 !important; /* Замените 'red' на нужный вам цвет */
}

.custom-select .ant-select-selector,
.ant-select-selector .ant-select-open .ant-select-selector {
  height: 32px !important;
  border-color: #5d5f7c !important;
  background-color: transparent !important;
  color: #c0c0c0 !important;
}

.custom-select .ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-open .ant-select-selector {
  border-color: #5468ff !important; /* Цвет рамки при фокусе */
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2); /* Тень вокруг элемента */
}

:where(.css-dev-only-do-not-override-mzwlov).ant-select-outlined:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer):hover
  .ant-select-selector {
  border-color: #006bd6 !important;
}

@media (max-width: 700px) {
  .logo {
    height: 2em;
  }
  :where(.css-dev-only-do-not-override-mzwlov).ant-input {
    font-size: 11px;
  }

  .custom-select .ant-select-selector,
  .ant-select-selector .ant-select-open .ant-select-selector {
    height: 27px !important;
    font-size: 11px !important;
  }

  .ant-select-search-icon {
    color: #c0c0c0 !important;
  }

  .css-i4bv87-MuiSvgIcon-root {
    width: 0.8em !important;
    height: 0.8em !important;
  }
}

@media (max-width: 450px) {
  .logo {
    height: 1.5em;
  }
  :where(.css-dev-only-do-not-override-mzwlov).ant-input {
    font-size: 8px;
  }

  .custom-select .ant-select-selector,
  .ant-select-selector .ant-select-open .ant-select-selector {
    height: 25px !important;
    font-size: 10px !important;
  }

  .ant-select-search-icon {
    color: #c0c0c0 !important;
  }

  .css-i4bv87-MuiSvgIcon-root {
    width: 0.7em !important;
    height: 0.7em !important;
  }

  .custom-form-control-label .MuiFormControlLabel-label {
    font-size: 8px !important;
  }
}

.dOkuIJ * {
  width: 216px;
  font-family: 'Titillium Web', sans-serif;
  font-weight: bold;
  border-radius: 13px;
  border: 1px solid rgb(25, 124, 216);
  text-transform: uppercase;
  background-color: rgb(25, 124, 216);
}

.dOkuIJ * :disabled {
  background-color: rgba(25, 124, 216, 0.29);
  color: #ffffff45;
  border-color: rgb(25, 124, 216);
}

.flbzLR * {
  width: 216px;
  font-family: 'Titillium Web', sans-serif;
  font-weight: bold;
  border-radius: 13px;
  border: 1px solid rgb(25, 124, 216);
  text-transform: uppercase;
  background-color: rgb(25, 124, 216);
}

.flbzLR * :disabled {
  background-color: rgba(25, 124, 216, 0.29);
  color: #ffffff45;
  border-color: rgb(25, 124, 216);
}

@media (max-width: 700px) {
  .dOkuIJ * {
    font-size: 11px;
  }

  .flbzLR * {
    font-size: 11px;
  }

  .ciGJFC button {
    width: 100%;
  }

  .cLXDYA button {
    width: 100%;
  }

  .dOkuIJ * {
    width: 100% !important;
  }

  .flbzLR * {
    width: 100% !important;
  }
}

@media (max-width: 450px) {
  .dOkuIJ * {
    font-size: 10px;
  }

  .flbzLR * {
    font-size: 10px;
  }

  .custom-form-control-label {
    margin-bottom: 2px;
  }
}

:where(.css-dev-only-do-not-override-mzwlov).ant-spin-nested-loading .ant-spin-container::after {
  background-color: transparent;
}

.geYEVu {
  width: 1000px !important;
}
